module.exports = isFunction;
var toString = Object.prototype.toString;
function isFunction(fn) {
  if (!fn) {
    return false;
  }
  var string = toString.call(fn);
  return string === '[object Function]' || typeof fn === 'function' && string !== '[object RegExp]' || typeof window !== 'undefined' && (
  // IE8 and below
  fn === window.setTimeout || fn === window.alert || fn === window.confirm || fn === window.prompt);
}
;